.home {
  padding: 0 !important;
  background-image: url('../assets/Background-v1.jpg');
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh !important;
}

.form {
  flex-direction: column;
  max-width: 40%;
  margin-left: 40px;
  float: left;
}

.block {
  display: inline-block;
}

.label {
  text-align: left;
  float: left;
  color: white;
}

.img-fluid {
  /* position: sticky; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 20px;
  background: transparent;
  z-index: 1;
  height: 60px;
  padding: 5px;
}

.img-home {
  width: 100%;
  height: auto;
}

.avatar {
  height: 94%;
}

.left-fold {
  margin-top: 100px !important;
}

.right-fold {
  background-color: rgba(28, 25, 27, 0.8);
  border-radius: 25px;
  text-align: center;
  padding: 20px;
  justify-content: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px !important;
}

.right-fold-top {
  background-color: rgba(28, 25, 27, 0.8);
  border-radius: 25px;
  text-align: center;
  padding: 20px;
  justify-content: center;
  align-content: center;
  top: 1vh;
  left: 15vw;
  margin: auto;
  z-index: 9;
  position: absolute;
}

.right-fold-top-single {
  background-color: rgba(28, 25, 27, 0.8);
  border-radius: 25px;
  text-align: center;
  padding: 20px;
  justify-content: center;
  align-content: center;
  top: 20vh;
  left: 15vw;
  margin: auto;
  z-index: 9;
  position: absolute;
}

.restaurant-reservation {
  top: 25vh;
  left: 12vw;
  border-radius: 25px;
  text-align: center;
  padding: 20px;
  justify-content: center;
  align-content: center;
  margin: auto;
  z-index: 9;
  position: absolute;
}

.header {
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 43px;
  letter-spacing: 0px;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
}

.description {
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  padding-top: 25px;
  margin-bottom: 20px;
}

.sub-description {
  color: white !important;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  margin-bottom: 15px;
  padding-left: 12px;
  padding-right: 12px;
}

.header-top {
  color: black;
  font-weight: 700;
  font-size: 20px;
  line-height: 43px;
  letter-spacing: 0px;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  position: absolute;
  top: 23vh;
  left: 16vw;
  margin: auto;
  max-width: 30vw;
}

.header-top-crypto {
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0px;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  position: absolute;
  top: 23vh;
  left: 19vw;
  margin: auto;
  max-width: 20vw;
}

.header-2 {
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-left: 40px;
  float: left;
}

.header-3 {
  color: black;
  font-weight: 700;
  font-size: 15px;
  margin-left: 40px;
  float: left;
}

.column {
  float: left;
  width: 50%;
  display: inline-block;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.head {
  color: white;
  font-size: 22px;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
  line-height: 30px;
}
.para {
  color: white;
  font-size: 26px;
  font-family: 'Montserrat', Arial, Tahoma, sans-serif;
}

.landing-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.landing-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin: 0 auto;
}

.landing-col-6-top {
  float: left;
  flex: 0 0 50%;
  margin: 0 auto;
  max-width: 30%;
}

.landing-col-6-top-single {
  float: left;
  flex: 0 0 30%;
  margin: 0 auto;
}

.landing-col {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.landing-container-fluid,
.landing-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.avatar-container.inline {
  display: none;
}

.qr svg {
  background-color: #fff;
  border-radius: 5px;
  height: 270px;
  width: 270px;
}

.qr-big svg {
  background-color: #fff;
  border-radius: 5px;
  height: 16vw;
  width: 16vw;
  position: absolute;
  top: 37vh;
  left: 21vw;
  margin: auto;
}

.qr-big-crypto svg {
  background-color: #fff;
  border-radius: 5px;
  height: 16vw;
  width: 16vw;
  position: absolute;
  top: 37vh;
  left: 19vw;
  margin: auto;
}

.btn-home {
  width: auto;
  padding: 0;
  color: white;
  background-color: transparent;
  position: absolute;
  left: 10px;
  top: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  z-index: 20;
}

.btn-age-validation {
  width: auto;
  color: white;
  background-color: transparent;
  position: absolute;
  left: 40%;
  top: 35px;
  border: none;
  cursor: pointer;
  z-index: 20;
  font-size: 22px;
}

.btn-verifier {
  width: auto;
  color: white;
  background-color: transparent;
  position: absolute;
  left: calc(40% + 180px);
  top: 35px;
  border: none;
  cursor: pointer;
  z-index: 20;
  font-size: 22px;
}

.block-sq {
  position: relative; /* change #1 */
  width: 100%;
  height: 0s;
  padding-bottom: 100%;
  border: 10px solid gray;
  background-size: cover;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}

.homepage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 100vh;
  height: auto;
  max-width: 1200px;
  overflow: auto;
  padding: 12% 30px 100px 30px;
}

.homepage-element-foreground {
  max-width: 25%;
  max-height: 100%;
  bottom: 30px;
  float: left;
  display: inline-block;
  position: relative;
  transform-style: preserve-3d;
  text-align: center;
}

.homepage-element {
  max-width: 25%;
  max-height: 100%;
  padding: 1px;
  float: left;
  top: 15px;
  display: inline-block;
  position: relative;
  text-align: center;
}

.block-sq span {
  position: absolute; /* change #2 */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; /* change #3 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  border: 5px;
  text-align: center;
  color: white;
  background-color: rgba(28, 25, 27, 0.8);
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 35px;
  border-radius: 10px;
}

@media only screen and (max-device-width: 576px) {
  .btn-home {
    height: 50px;
  }
  .btn-home img {
    height: 50px;
  }
  .btn-age-validation {
    position: absolute;
    top: 30px;
    left: auto;
    right: 20px;
    width: auto !important;
    font-size: 16px;
  }
  .btn-verifier {
    position: absolute;
    top: 60px;
    left: auto;
    right: 20px;
    width: auto !important;
    font-size: 16px;
  }
  .homepage {
    padding-top: 100px;
  }
  .homepage-element {
    width: 100%;
    max-width: 100%;
    float: none;
    clear: both;
    top: auto;
    bottom: auto;
    margin-bottom: 50px;
  }
  .homepage-element-foreground {
    width: 100%;
    max-width: 100%;
    float: none;
    clear: both;
    top: auto;
    bottom: auto;
    margin-bottom: 50px;
  }
  .homepage-element-foreground .description,
  .homepage-element .description {
    padding-top: 10px;
  }
}

/* Demo only */
.row-sq {
  display: flex;
  justify-content: space-between;
}
.column-sq {
  width: 80%;
  justify-content: space-between;
}

.btn {
  width: 50%;
  padding: 10px;
  color: white;
  background-color: black;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 15px;
}

.btn-primary {
  margin-top: 40px;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

#restrictedContentStillImage {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.stack-top {
  z-index: 9;
}

@media only screen and (max-device-width: 1366px) {
  .upper-fold {
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 768px) {
  .left-fold {
    display: none;
  }

  .avatar-container.inline {
    display: flex;
    height: 200px;
    align-self: center;
  }

  .landing-col-6 {
    flex: 1;
    max-width: 80%;
  }
}

@media (min-width: 576px) {
  .landing-container {
    max-width: 540px;
  }
}

@media (max-width: 1000px) {
  .btn-age-validation {
    width: 20%;
  }
}

@media (max-width: 1000px) {
  .btn-verifier {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .landing-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .landing-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .landing-container {
    max-width: 1140px;
  }
}
